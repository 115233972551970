// LocationSection.js
import React from 'react';
import "./LocationSection.css";
import divider from "../../../src/Assests/divider.png";

const LocationSection = () => {
  return (
    <div className='LocationContainer'>
      <h2>Location</h2>
      <img className='img' src={divider} alt='divider'/>
      <p>The ceremony will be held at Manhattan Penthouse on 5th Avenue at 3:30PM. Check out the map below for further details.</p>
      <img className='map'  src='https://www.researchgate.net/profile/Vincent-Labatut/publication/308321200/figure/fig18/AS:642475333009417@1530189464757/The-famous-rectilinear-network-of-Manhattan-USA-Google-Maps.png' alt='map'/>
    </div>
  );
};

export default LocationSection;