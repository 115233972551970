import React, { useState } from "react";
import axios from 'axios';
import './PageRsvp.css';

const PageRsvp = () => {
  const [guest_count, setguest_count] = useState();
  const [no_of_days_stay, setno_of_days_stay] = useState("");
  const [pickup_point, setpickup_point] = useState("");
  const [dropoff_point, setdropoff_point] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
   
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate that all fields are filled
    if (
      !guest_count ||
      !no_of_days_stay ||
      !pickup_point ||
      !dropoff_point
    ) {
      setErrorMessage("Please fill in field(s)");
      return;
    }
    // Clear the error message when the form is successfully submitted
    setErrorMessage("");

    // Here, can handle the form submission, e.g., send the data to a server or perform some action
    try {
      // Make an HTTP POST request using axios
      let url = 'http://3.120.18.133:3020';
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }
      };
      let data = {
        guest_count,
        no_of_days_stay,
        pickup_point,
        dropoff_point 
      }
      console.log(data);
      const response = await axios.post(`${url}/rsvp`, data ,axiosConfig);

      // Handle the response (you can log or update state accordingly)
      console.log('API Response:', response.data);
    } catch (error) {
      // Handle errors (log or update state with an error message)
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="rsvpContainer">
      <h1 className="title">RSVP</h1>

      <form onSubmit={handleSubmit} >
        <div className="form-row">
        <label className="col1">
            Pickup Point 
            <span className="require" >*</span>
            <input
              className="box"
              type="text"
              value={pickup_point}
              onChange={(e) => setpickup_point(e.target.value)}
            />
          </label>
          
          <label className="col2_rsvp">
            Number of Guests
            <span className="require" >*</span>
            <input
              className="box"
              type="number"
              value={guest_count}
              onChange={(e) => setguest_count(e.target.value)}
            />
          </label>
        </div>

        <div className="form-row">
        <label className="col1">
            DropOff Point 
            <span className="require" >*</span>
            <input
              className="box"
              type="text"
              value={dropoff_point}
              onChange={(e) => setdropoff_point(e.target.value)}
            />
          </label>
          
          <label className="col2_rsvp">
            No of Days to Stay 
            <span className="require" >*</span>
            <input
              className="box"
              type="number"
              value={no_of_days_stay}
              onChange={(e) => setno_of_days_stay(e.target.value)}
            />
          </label>
        </div>

        <button className="btn" type="submit">Send my RSVP today</button>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default PageRsvp;