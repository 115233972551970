// Header.js
import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header >
      <div className="header-container">
        <h1>
          <a className="Title" href="/home">Wedding Planner</a>
        </h1>

        <p className='nav'>
          <a className="reg" href="/register">REGISTER</a>
          <span className="space-between"></span>
          <a className="rsvp" href='/rsvp'>RSVP</a>
        </p>

        {/*<nav>
          <ul>
           <li><a href="/register">Register</a></li>
            <li><a href="/rsvp">RSVP</a></li>
          </ul>
        </nav>*/}

      </div>
    </header>
  );
}

export default Header;