import React, { useState } from 'react';
import axios from 'axios';
import './Register.css';

const RegisterPage = () => {
  const [groom_name, setgroom_name] = useState("");
  const [bride_name, setbride_name] = useState("");
  const [sundower_date, setsundower_date] = useState("");
  const [sundower_venue, setsundower_venue] = useState("");
  const [nikah_date, setnikah_date] = useState("");
  const [nikah_venue, setnikah_venue] = useState("");
  const [mehndi_date, setmehndi_date] = useState("");   
  const [mehndi_venue, setmehndi_venue] = useState("");
  const [reception_date, setreception_date] = useState("");
  const [reception_venue, setreception_venue] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  //copy rsvp variables
  const [showPopup, setShowPopup] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [marriageId, setMarriageId] = useState(null);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate that all fields are filled
    if (
      !groom_name ||
      !bride_name ||
      !sundower_date ||
      !sundower_venue ||
      !nikah_date ||
      !nikah_venue ||
      !mehndi_date ||
      !mehndi_venue ||
      !reception_date ||
      !reception_venue
    ) {
      setErrorMessage("Please fill in field(s)");
      return;
    }
    // Clear the error message when the form is successfully submitted
    setErrorMessage("");
    
    // Here, you can handle the form submission, e.g., send the data to a server or perform some action

    try {
      // Make an HTTP POST request using axios
      let url = 'http://3.120.18.133:3020';
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }
      };
      let data = {
        groom_name,
        bride_name,
        sundower_date,
        sundower_venue,
        nikah_date,
        nikah_venue,
        mehndi_date,
        mehndi_venue,
        reception_date,
        reception_venue,
      }
      console.log(data);
      let response = await axios.post(`${url}/marriage`, data ,axiosConfig);
      response = JSON.parse(JSON.stringify(response.data));

      // Handle the response (you can log or update state accordingly)
      console.log('API Response:', response.data);

      // Set marriageId and show the popup upon successful registration
      console.log('Marriage ID:', response.data._id);
      setMarriageId(response.data._id);
      setShowPopup(true);
    } catch (error) {
      // Handle errors (log or update state with an error message)
      console.error('Error submitting form:', error);
    }
  };

  const handleCopyLink = () => {
    const rsvpLink = `https://wedding.dmdmax.com//rsvp?mid=${marriageId}`;
    const textField = document.createElement('textarea');
    textField.innerText = rsvpLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    setCopiedToClipboard(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setCopiedToClipboard(false);
  };

  return (
    <div className='registerContainer'>
      <h1 className="title">register marriage</h1>
      <form onSubmit={handleSubmit}>
        {/* Groom and Bride Names */}
        <div className="form-row">
          <label className='col1'>
            Groom Name:
            <span className="require" >*</span>
            <input
              className='box'
              type="text"
              value={groom_name}
              onChange={(e) => setgroom_name(e.target.value)}
            />
          </label>
          <label className='col2_b'>
            Bride Name:
            <span className="require" >*</span>
            <input
              className='box'
              type="text"
              value={bride_name}
              onChange={(e) => setbride_name(e.target.value)}
            />
          </label>
        </div>

        {/* Sundower Date and Venue */}
        <div className="form-row">
          <label className='col1'>
            Sundower Date:
            <span className="require" >*</span>
            <input
              className='box'
              type="date"
              value={sundower_date}
              onChange={(e) => setsundower_date(e.target.value)}
            />
          </label>
          <label className='col2'>
            Sundower Venue:
            <span className="require" >*</span>
            <input
              className='box'
              type="text"
              value={sundower_venue}
              onChange={(e) => setsundower_venue(e.target.value)}
            />
          </label>
        </div>

        {/* Nikah Date and Venue */}
        <div className="form-row">
          <label className='col1_nikkah'>
            Nikah Date:
            <span className="require" >*</span>
            <input
              className='box'
              type="date"
              value={nikah_date}
              onChange={(e) => setnikah_date(e.target.value)}
            />
          </label>
          <label className='col2_nikkah'>
            Nikah Venue:
            <span className="require" >*</span>
            <input
              className='box'
              type="text"
              value={nikah_venue}
              onChange={(e) => setnikah_venue(e.target.value)}
            />
          </label>
        </div> 

        {/* Mehndi Date and Venue */}
        <div className="form-row">
          <label className='col1'>
            Mehndi Date:
            <span className="require" >*</span>
            <input
              className='box'
              type="date"
              value={mehndi_date}
              onChange={(e) => setmehndi_date(e.target.value)}
            />
          </label>
          <label className='col2'>
            Mehndi Venue:
            <span className="require" >*</span>
            <input
              className='box'
              type="text"
              value={mehndi_venue}
              onChange={(e) => setmehndi_venue(e.target.value)}
            />
          </label>
        </div>

        {/* Reception Date and Venue */}
        <div className="form-row">
          <label className='col1'>
            Reception Date:
            <span className="require" >*</span>
            <input
              className='box'
              type="date"
              value={reception_date}
              onChange={(e) => setreception_date(e.target.value)}
            />
          </label>
          <label className='col2'>
            Reception Venue:
            <span className="require" >*</span>
            <input
              className='box'
              type="text"
              value={reception_venue}
              onChange={(e) => setreception_venue(e.target.value)}
            />
          </label>
        </div>

        <button className="btn" type="submit">Register Marriage</button>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>

      {showPopup && (
        <div className="popup">
          <h2>Marriage has been registered</h2>
          <p>RSVP Link: {`https://wedding.dmdmax.com/rsvp?mid=${marriageId}`}</p>
          <button onClick={handleCopyLink}>Copy Link</button>
          <button onClick={closePopup}>Close</button>
          {copiedToClipboard && (
            <p className="copied-message">Copied to Clipboard!</p>
          )}
        </div>
      )}

    </div>
  );
}

export default RegisterPage;
