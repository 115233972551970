import React from 'react';
import './App.css';
import { BrowserRouter,  Routes , Route} from 'react-router-dom';
import Header from './Components/Header/Header';
import HomePage from './Pages/HomePage/HomePage';
import RegisterPage from './Pages/Register/Register';
import PageRsvp from './Pages/PageRsvp/PageRsvp';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header/>
      <BrowserRouter> 
        <Routes>
            <Route index element={<HomePage/>}/>
            <Route path="/home" element={<HomePage/>} />
            <Route path="/register" element={<RegisterPage/>} />
            <Route path="/rsvp" element={<PageRsvp/>} />
        </Routes>
      </BrowserRouter>
      <Footer/>

    </div>
  );
}

export default App;