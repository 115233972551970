// Footer.js

import React from 'react';
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      {/*<p>LILY - A WEDDING WORDPRESS THEME - HANDCRAFTED BY LOVE + THEMECHILLS</p>*/}
      <p>&copy; 2023 Wedding Website. All rights reserved.</p>   
    </footer>
  );
};

export default Footer;
