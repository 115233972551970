// StorySection.js

import React from 'react';
import "./OurStorySection.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import divider from "../../../src/Assests/divider.png";

const OurStorySection = () => {
  
  const owlCarouselOptions = {
    items: 3, // Number of items to display in the carousel
    loop: true,
    margin: 10,
    autoplay: false,
    autoplayTimeout: 3000, // Set the duration for each slide in milliseconds
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
 
  return (
    <div className='StoryContainer'>
      <h2>Our Story</h2>
      <img className='img' src={divider} alt='divider'/>
      <p>The story of how we met begins in the beautiful city of New York, the beginning of autumn, the start of a love
        story, which we will share with you in the upcoming months.</p>
      <img className='sig' src="https://themechills.com/preview/lily/wp-content/uploads/sites/6/2017/05/signatures.jpg" 
      alt="Signatures" />
      
      <div className="story-pictures">
        <OwlCarousel {...owlCarouselOptions}> 
          <img className='pic' src="https://images.unsplash.com/photo-1491582990992-68ec88e070a3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80" 
            alt="Our Story 1" />
          <img className='pic' src="https://images.unsplash.com/photo-1497120573086-6219573cf71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80" 
            alt="Our Story 2" />
          <img className='pic' src="https://images.unsplash.com/photo-1474552226712-ac0f0961a954?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80" 
            alt="Our Story 3" />
          <img className='pic' src="https://images.unsplash.com/photo-1509927083803-4bd519298ac4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" 
            alt="Our Story 4" />   
        </OwlCarousel> 
      </div>
      
    </div>
  );
};

export default OurStorySection;
