// HomePage.js
import React from 'react';
import PosterSlider from '../../Components/PosterSlider/PosterSlider';
import OurStorySection from '../../Components/OurStorySection/OurStorySection';
import LocationSection from '../../Components/LocationSection/LocationSection';

const HomePage = () => {
  return (
    <div>
      <PosterSlider/>
      <OurStorySection/>
      <LocationSection/>
    </div>
  );
}

export default HomePage;
