// PosterSlider.js

import React from 'react';
import {useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PosterSlider.css'; 

const PosterSlider = () => { 
  const navigate = useNavigate();

  const posters = [
    'https://images.unsplash.com/photo-1591604466107-ec97de577aff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80',
    'https://images.unsplash.com/photo-1571984129095-1bb1b3ccc5be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1804&q=80',
    'https://images.unsplash.com/photo-1533091090875-1ff4acc497dd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80',

  ]


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,   // Enable automatic sliding
    autoplaySpeed: 2000, // Set the duration for each slide in milliseconds
    arrows: true, // Hide navigation arrows

    responsive: [
      {
        breakpoint: 426,
        settings:{
          swipe: false,
        },
      },
      {
        breakpoint: 321,
        setting:{
          swipe: false,
        },
      },
    ],
  };

  return (
    <div className="poster-slider" >
        <Slider {...settings}>
        {posters.map((poster, index) => (
            <div key={index} className="poster-slide">
                <img src={poster} alt={`Poster ${index + 1}`} />
                {/* Additional div on top of each slide */}
                <div className="slide-content">
                    <h2 >HE ASKED, SHE SAID YES, WE'RE GETTING</h2>
                    <h3>MARRIED!</h3>
                    <h4>
                        <span>Saturday 14th December 2022</span>
                        <span>InterContinental Fiji Golf Resort & Spa</span>
                    </h4>
                    <button className="rsvp-button" onClick={() => navigate("/rsvp")}>Click here to RSVP</button>
                </div>
            </div>
        ))}
        </Slider>
    </div>
  );
}

export default PosterSlider;
